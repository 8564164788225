var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { staticClass: "p-3", attrs: { gutter: [16, 16] } },
    [
      _c(
        "a-col",
        { attrs: { span: 18, xs: 24, s: 24, md: 24, lg: 18 } },
        [
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.supplierType.label) } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: _vm.formRules.supplierType.decorator,
                      expression: "formRules.supplierType.decorator"
                    }
                  ],
                  attrs: {
                    name: _vm.formRules.supplierType.name,
                    placeholder: _vm.$t(_vm.formRules.supplierType.placeholder),
                    "allow-clear": true
                  }
                },
                _vm._l(_vm.dataSupplierType, function(data, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: data.value } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" " + _vm._s(data.value) + " ")
                          ]),
                          _vm._v(" " + _vm._s(data.value) + " ")
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t(_vm.formRules.supplierNumber.label) } },
            [
              _c("p", [
                _vm._v(_vm._s(_vm.supplierNumber ? _vm.supplierNumber : "-"))
              ])
            ]
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t("lbl_supplier_code") } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "supplierCode",
                      {
                        rules: [
                          {
                            required: true,
                            message: this.$t("lbl_validation_required_error")
                          }
                        ]
                      }
                    ],
                    expression:
                      "[\n          'supplierCode',\n          {\n            rules: [\n              {\n                required: true,\n                message: this.$t('lbl_validation_required_error')\n              }\n            ]\n          }\n        ]"
                  }
                ],
                attrs: {
                  name: "supplierCode",
                  placeholder: _vm.$t("lbl_type_here"),
                  "allow-clear": ""
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }